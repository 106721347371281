
function About() {
    return (
          <div>
          
              <h2>About Section</h2>
      </div>
    );
  }
  
  export default About;
  