
import React from "react";
import Navbar from "./components/Navbar"
import Home from "./containers/Home"
import Projects from "./containers/Projects"
import About from "./containers/About"
import Resume from "./containers/Resume"
import Contact from "./containers/Contact"
import Project from "./containers/Project"
import {
  BrowserRouter as Router,
  Routes,
  Route

} from "react-router-dom"



function App() {
  return (
   
    <Router>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route path="resume" element={<Resume />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="shieldmaiden" element={<Project />} />
        </Route>
      </Routes>
    </Router>
    
  );
}

export default App;
