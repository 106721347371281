
import ProjectCard from "../components/ProjectCard";
import data from "../data/projects.json"
import "../components/Choices.css"
import "./Projects.css"

function Projects() {
  const PersonalProjects = data.personal;
  const ProfessionalProjects = data.professional;

  return (
    <div id="projects">
      <div className="section-header container-fluid">
        <h2 className="text-center">Personal Work</h2>
        <hr class="hr hr-blurry me-2" />
        <p className="container-fluid text-center">Below you will find projects I created for educational purposes.</p>
      </div>
      <div className="card-section d-flex justify-content-around w-100 mb-5">
        {PersonalProjects.map(project => {
          return (
            <ProjectCard projectData={project} />
          )
        })}
      </div>
      <div className="section-header">
        <h2 className="text-center ">Professional Work</h2>
        <hr class="hr hr-blurry me-2" />
        <p className="container text-center">Below you will find projects I worked on before deciding to change careers in the game developement industry.</p>
      </div>
      <div className="card-section d-flex container-fluid justify-content-around w-100 mb-5 ">
        {ProfessionalProjects.map(project => {
          return (

            <ProjectCard projectData={project} />
          )
        })}
      </div>
    </div>
  );
}

export default Projects;
