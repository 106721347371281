import "./Navbar.css"
import {
    Link,
    Outlet
} from "react-router-dom"
import 'mdb-ui-kit/css/mdb.min.css';

function Navbar() {
    return (
        <>

            {/* <div class="collapse" id="navbarToggleExternalContent">  fixed-top*/}
            <nav className="navbar navbar-expand-md navbar-dark nbar">
                <div className="container-fluid justify-content-end align-items-center">
                    <Link className="navbar-brand brand align-self-center" to="/" ><div className="">  Anastasios Cholopoulos <br /> Games Designer</div></Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav ">
                        <li className="nav-item">
                                <Link className="nav-link" to="/" hrefLang="#projects">Projects</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/resume">CV</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link " to="/about">About</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link " to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>

                </div>

            </nav>
            {/* </div> */}
            {/* <nav class="navbar navbar-dark bg-dark">
                <div class="container-fluid">
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbarToggleExternalContent"
                        aria-controls="navbarToggleExternalContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i class="fas fa-bars"></i>
                    </button>
                </div>
            </nav> */}
            <Outlet />

        </>
    );
}

export default Navbar;