import "./home.css"

import Projects from "./Projects";
import Footer from "./Footer"
function Home() {
  return (
    <div  >
      <div className="p-5 text-center bg-image " style={{ backgroundImage: "url('https://images.unsplash.com/photo-1668423389856-2a82e96ee01b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80')", height: '100vh' }}>
        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
          <div className="d-flex justify-content-center align-items-start h-100">
            <div className="text-white">
              <h1 className="mt-5">Hi! I'm Anastasios</h1>
              <h2 className="mt-5">Welcome to my Game Design Portfolio</h2>
              <br />
              <h4 className="mb-4">Projects</h4>
              <h4 className="mb-4"> <a href="#projects"><i className="fas fa-chevron-down"></i></a></h4>
              {/* <a className="btn btn-outline-light btn-lg" href="#!" role="button">Call to action</a> */}
              <p className="img-rights text-muted">Image rights John Doe <a href=""  rel="noreferrer">unsplash.com</a></p>
            </div>
          </div>
        </div>
      </div>
        <Projects></Projects>
      
      
      {/* <Project></Project> */}
      <Footer/>
    </div>
  );
}

export default Home;
