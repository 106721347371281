import {useRef} from "react"
import useStateWithCallback from 'use-state-with-callback';
import ScrollToTop from "../components/helpers/ScrollToTop";
import "./Project_f.css"
import "../components/Choices.css"
import "../fonts/skranji-bold.ttf"
import Section from "../components/ProjectEssentials/Section";
import data from "../data/projects.json"

function Project() {
    const Sections = data.personal[0].details.sections;
    let detailsRef = useRef();
    const [Details,setDetails]= useStateWithCallback(false,Current =>{
        if(Current){
           window.scrollTo({behavior:"smooth",top:detailsRef.current.offsetTop,block:"start"});
    }
    });
    
    const handleDetailsClick = (e) =>
    {
        e.preventDefault();        
        setDetails(true);
    
    };

    return (
        <div className="project-ct" id="shieldmaiden">
            <ScrollToTop/>
            <div className="pr-hero">
                <div className="pr-banner container-fluid">
                    <img src="/Shieldmaiden.png" alt="" className="pr-logo img-fluid" />
                </div>
                <div className="pr-hero-info container-fluid ">
                    <div className="pr-hero-info-video container-fluid">
                        <iframe title="shield" className="project-main-video" src="https://www.youtube.com/embed/bU1bCg5uTeE?autoplay=1&mute=1" allow="autoplay; encrypted-media" frameBorder="0"></iframe>
                    </div>
                    <div className="pr-hero-info-text container-fluid">
                        <p>A challenging top down hack n' slash game
                            to play, preferably, with a friend!
                            <br />Play as one of the shieldmaiden sisters and protect Harald, the chosen one,
                            against hordes of enemies that want him dead.</p>
                        <ul className="project-base-kp">
                            <li className="keypoint">Fast-paced, action-packed gameplay!</li>
                            <li className="keypoint">Two characters, two play styles to choose from!</li>
                            <li className="keypoint">Different kinds of enemies!</li>
                            <li className="keypoint">Procedural snowy levels!</li>
                            <li className="keypoint">Unique co-op mechanics!</li>
                            <li className="keypoint">A challenge that pushes your cooperation skills to their limits!</li>
                        </ul>
                    </div>
                </div>
                <div className="pr-hero-details container">
                    <p className="container-fluid">Shieldmaiden is a twin-stick slasher, local co-op game where players must work together to keep a, partially controlled, follower AI alive as long as possible.</p>
                    <p className="container-fluid">I wanted to achieve invoking the feeling of fellowship through the mechanics of the game; whilst introducing early in the game a big “roguelike” challenge to the players.</p>
                     <div className="options-ct container-fluid">
                            <a href="https://niephox.itch.io/shieldmaiden" target="_blank" rel='noreferrer' className="option">I want to play the game! <i className="fab fa-lg fa-itch-io option-icon"></i> </a>
                            <button className="option" onClick={handleDetailsClick} disabled="true">I'd like to learn more about it!</button>
                            <button className="option">What other projects have you created?</button>
                    </div>
                </div>
            </div>
           
            {/* { Details && 
                <div className="pr-nav"  ref={detailsRef} >
                <nav className="navbar navbar-expand-lg fixed-top">
                <button
                        className="navbar-toggler"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbarNavAltMarkup"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className=" collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            {Sections.map((data, index) => {
                                const title = data.title.replace(" ", "");
                                return (
                                    <a className="nav-link " aria-current="page" href={`#` + title}>{data.title}</a>
                                )
                            })}
                        </div>
                    </div>
                </nav>
            </div>} */}
            {/* { Details &&
            <div className="pr-details">
                {
                    Sections.map((data, index) => {
                        return (
                            <Section data={data} key={index} />
                        )
                    })
                }
            </div>} */}
        </div>
    );
}

export default Project;