import "./ProjectCard.css"
import {Link} from "react-router-dom";

function ProjectCard(props) {
    return (
    <Link to={props.projectData.projectPage} className="card border border-dark h-100 c-card ripple"  >
            <div className="card-header text-center c-header" style={{ textTransform: "Uppercase" }}>{props.projectData.title}</div>
            <div className="bg-image c-image  " data-mdb-ripple-color="light">
                <img src={props.projectData.cardImgUrl} alt="" className="card-img h-100" style={{ minHeight: "100%", height: "100%" }} />
            </div>
            <p className="card-text c-genre">{props.projectData.genreDesc}</p>
            <div className="card-body">
                <p className="card-text">{props.projectData.shortDesc}</p>
            </div>
            <div className="card-footer">
                <p className="text-bold text-center" >
                {
                    props.projectData.roles.map( (role,index) => {
                        const spacer = props.projectData.roles.length - 1 !== index ? ` / ` : ``;
                       return ( role + spacer )
                    })
                }
                </p>
                
            </div>
        </Link>


    );
}

export default ProjectCard;
