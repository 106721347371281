
function Contact() {
  return (
    <div>
      <h2>Contact Section</h2>
      <ul>
        <li><i class="fab fa-linkedin"></i></li>
        <li><i class="fab fa-itch-io"></i></li>
        <li><i class="fas fa-envelope"></i></li>
      </ul>
    </div>
  );
}

export default Contact;
