import "./Footer.css"
function Footer() {
    return (
        <div className="text-white text-center footer-ct">
            <div className="container stick-bottom">
                <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
                    <i class="fab fa-lg fa-linkedin"></i>
                </a>
                <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
                    <i class="fab fa-lg fa-itch-io"></i>
                </a>
                <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button">
                    <i class="fas fa-lg fa-envelope"></i>
                </a>



            </div>
        </div>
    )
}

export default Footer;