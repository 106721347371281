import { useLayoutEffect,useRef,useState } from "react"
import {Link} from "react-router-dom"
import ResumeListItem from "../components/ResumeListItem"
import "./Resume.css"
import data from "../data/resume.json"

function Resume() {
    const experience = data.experience;
    const education = data.education;
    const generalExp = data.experienceGeneral;
    const ref = useRef(null);
    const [height,setHeight] = useState(0);

    useLayoutEffect(() =>
    {
      setHeight(ref.current.clientHeight);
    },[height]);

    return (
        <div className="container-fluid resume-ct mt-2 mr-5 ml-5">
            <h2 className="container-fluid fw-bold text-center mt-4 mb-4">Curriculum Vitae</h2>
            <hr/>
            <div className="container-fluid r-content">
                <div className="container-fluid">
                    <div className="container-fluid brief">
                        <h5>Anastasios Cholopoulos</h5>
                        <ul className="brief-contact-list">
                            <li>
                                <i className="fas fa-map-marker-alt fa-lg loc-col"></i>Based in Greece</li>
                            <li>
                                <a
                                    rel="noreferrer"
                                    href="https://www.linkedin.com/in/anastasios-cholopoulos-38773b149"
                                    className="brief-contact"
                                    target="_blank">
                                    <i className="fab fa-lg fa-linkedin"></i>Anastasios Cholopoulos</a>
                            </li>
                            <li>

                                <a
                                    rel="noreferrer"
                                    href="https://niephox.itch.io/"
                                    target="_blank"
                                    className="brief-contact">
                                    <i className="fab fa-lg fa-itch-io"></i>Niephox</a>
                            </li>
                            <li>
                                <a
                                    rel="noreferrer"
                                    href="https://twitter.com/AnastasiosChol"
                                    target="_blank"
                                    className="brief-contact">
                                    <i className="fab fa-lg fa-twitter-square"></i>@AnastasiosChol</a>
                            </li>
                            <li>
                                <a
                                    rel="noreferrer"
                                    href="mailto:anastasioscholopoulos@gmail.com"
                                    target="email"
                                    className="brief-contact">
                                    <i className="fas fa-lg fa-envelope"></i>anastasioscholopoulos@gmail.com</a>
                            </li>
                        </ul>
                    </div>
                    <div className="container-fluid mt-5">
                        <h4>Education</h4>
                        <ol className="list-group list-group-light">
                            {education.map((entry, index) => {
                                return (<ResumeListItem
                                    key={index}
                                    date={entry.date}
                                    subHeading={entry.title}
                                    content={entry.school}/>)
                            })
}
                        </ol>
                    </div>
                </div>
                <hr />
                <div className="container-fluid"  ref={ref} style={{"minHeight":height}}>
                    <div className="r-exp">
                        <h4 className="text-start">Experience</h4>
                        <ul className="nav nav-pills mb-3" id="ex1" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active  exp-tab"
                                    id="ex1-tab-1"
                                    data-mdb-toggle="pill"
                                    href="#ex1-pills-1"
                                    role="tab"
                                    aria-controls="ex1-pills-1"
                                    aria-selected="true">Game Development</a >
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link  exp-tab"
                                    id="ex1-tab-2"
                                    data-mdb-toggle="pill"
                                    href="#ex1-pills-2"
                                    role="tab"
                                    aria-controls="ex1-pills-2"
                                    aria-selected="false">General</a >
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="ex1-content">
                        <div
                            className="tab-pane fade show active"
                            id="ex1-pills-1"
                            role="tabpanel"
                            aria-labelledby="ex1-tab-1">
                            <ol className="list-group list-group-light">
                                {experience.map((entry, index) => {
                                    return (<ResumeListItem
                                        key={index}
                                        date={entry.date}
                                        subHeading={entry.title}
                                        content={entry.company}/>)
                                })
}
                            </ol>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="ex1-pills-2"
                            role="tabpanel"
                            aria-labelledby="ex1-tab-2">
                            <ol className="list-group list-group-light">
                                {generalExp.map((entry, index) => {
                                    return (<ResumeListItem
                                        key={index}
                                        date={entry.date}
                                        subHeading={entry.title}
                                        content={entry.company}/>)
                                })
}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-center mt-2 mb-2 dl-bt">
                <Link
                    className="btn btn-outline btn-dark btn-lg"
                    role="button"
                    target="_blank"
                    
                    to="/CV_Anastasios_Cholopoulos.pdf">
                    Download
                </Link>
            </div>
        </div>
    );
}

export default Resume;

// <div className="p-5 text-center bg-image" style={{ backgroundImage:
// "url('https://images.unsplash.com/photo-1668767483947-8d37ddfc72db?ixlib=rb-4
// .
// 0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=
// 1 171&q=80')", height: '80vh' }}>       <div className="mask" style={{
// backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>         <div className="d-flex
// justify-content-center align-items-center h-100">           <div
// className="text-white">             <h1 className="mb-3">You can find my CV
// here</h1>             <Link className="btn btn-outline-light btn-lg"
// role="button" target="_blank" to="/CV_Anastasios_Cholopoulos.pdf"
// >Download</Link>             {/* <a className="btn btn-outline-light btn-lg"
// href="#!" role="button">Call to action</a> */}           </div> </div>
// </div>     </div>