
function ResumeListItem(props) {
    return (
        <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
                <div className="fw-bold"> {props.subHeading}</div>
                <div>{props.content}</div>
                <span >{props.date}</span> 
            </div>
        </li>

    );
}

export default ResumeListItem;